/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import {
  Hero,
  Branch,
  Button,
  Service,
  ServiceItems,
  Customers,
  HeadingLayout,
} from 'components/elements'

// Blog
import BlogContainer, { BlogConsumer } from 'components/shared/BlogContainer'
import BlogOverview from 'components/elements/BlogOverview'

const ServiceItemsContainer = () => (
  <div className="mt-5 mt-lg-0 pt-5 pt-lg-0">
    <div className="pt-lg-4">
      <ServiceItems />
    </div>
  </div>
)

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero fields={acf.banner} />

      <div data-component="home-1" className="py-lg-5 mt-5">
        <div className="pt-5">
          <Branch 
            fields={acf.blok_1} 
          />
        </div>
      </div>

      <div className="py-5" />

      <div data-component="home-2" className="py-lg-5 mt-5">
        <div className="pt-5">
          <Service 
            fields={acf.blok_2} 
            direction="right" 
            backgroundTitle={acf.blok_2.background_title} 
            content={<ServiceItemsContainer />}
          />
        </div>
      </div>

      <div data-component="home-3" className="py-lg-5 mt-5">
        <div className="pt-5">
          <Customers fields={acf.slider} />
        </div>
      </div>

      <div data-component="home-4" className="py-lg-5 mt-5 container">
        <div className="pt-5">
          <HeadingLayout heading={acf.blog.background_title} direction='left-left'>  
            <BlogContainer
              postType="blog"
              settings={{
                limit: 3
              }}
            >
              <BlogConsumer>
                {({ posts }) => {
                  return (
                    <>
                      <BlogOverview posts={posts} ids={acf.blog.articles} />
                    </>
                  )
                }}
              </BlogConsumer>
            </BlogContainer>
            <div className="d-flex justify-content-center my-5">
              <Button to={acf.blog.link.url}>
                {acf.blog.link.title}  
              </Button>
            </div>
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export default HomeTemplate
