/* eslint-disable import/no-unresolved */
import React from 'react'
import {
  Blog as Post
} from 'components/elements'

const BlogOverview = ({ posts }) => {
  return (
    <div className="row">
      {posts.map(({ node }) => (
        <div key={node.wordpress_id} className="col-lg-4 d-flex justify-content-center">
          <Post fields={node} />
        </div>
      ))}
    </div>
  )
}

export default BlogOverview